import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
					<path d="M5335 7402 c-3 -3 -5 -31 -5 -63 0 -56 1 -59 28 -64 67 -14 185 -58
245 -92 213 -118 313 -263 352 -505 l6 -38 66 0 66 0 -6 64 c-9 98 -63 247
-120 335 -85 130 -177 208 -327 277 -30 13 -64 29 -75 34 -44 21 -221 61 -230
52z"/>
					<path d="M5154 7095 c-198 -67 -315 -188 -361 -372 -14 -58 -17 -126 -18 -453
0 -406 2 -430 45 -430 39 0 71 25 160 126 l91 104 137 0 c155 0 220 13 322 62
81 39 185 141 222 218 26 56 61 197 52 212 -3 4 -25 8 -49 8 -44 0 -75 -19
-75 -45 0 -56 -109 -205 -183 -252 -81 -51 -138 -63 -299 -63 -164 0 -188 -6
-241 -63 -15 -16 -30 -27 -33 -25 -10 11 -25 365 -18 443 20 237 87 328 307
410 l47 18 0 63 c0 75 0 75 -106 39z"/>
					<path d="M5330 7061 c0 -59 0 -59 33 -67 139 -30 296 -186 323 -322 6 -32 7
-32 70 -32 74 0 73 0 49 95 -31 116 -107 221 -212 291 -58 38 -200 94 -240 94
-21 0 -23 -4 -23 -59z"/>
					<path d="M5230 6835 c-74 -24 -130 -84 -148 -160 -6 -22 -24 -95 -41 -162 -34
-134 -32 -164 15 -185 21 -10 44 -7 137 16 210 51 240 63 279 103 63 64 82
150 53 233 -44 125 -175 194 -295 155z m153 -91 c33 -18 64 -65 73 -112 27
-141 -151 -237 -261 -141 -70 61 -72 162 -5 229 52 52 122 60 193 24z"/>
					<path d="M2660 5245 l0 -255 55 0 55 0 0 89 c0 104 -3 101 106 101 132 0 187
48 187 163 0 65 -17 96 -73 135 -31 20 -44 22 -181 22 l-149 0 0 -255z m280
145 c25 -25 26 -71 2 -100 -15 -19 -31 -24 -95 -28 l-77 -5 0 77 0 76 75 0
c62 0 79 -4 95 -20z"/>
					<path d="M4450 5460 l0 -40 53 0 53 0 -4 40 -4 40 -49 0 -49 0 0 -40z" />
					<path d="M5570 5408 l0 -91 -26 24 c-22 20 -36 24 -91 24 -64 0 -66 -1 -103
-43 -50 -55 -64 -116 -46 -193 7 -30 18 -64 24 -76 17 -31 66 -63 107 -70 37
-6 115 14 115 29 0 4 7 8 15 8 8 0 15 -7 15 -15 0 -11 12 -15 45 -15 l45 0 0
255 0 255 -50 0 -50 0 0 -92z m-34 -126 c22 -15 34 -52 34 -110 0 -49 -4 -64
-25 -86 -35 -38 -85 -36 -120 6 -24 29 -27 38 -23 94 5 80 24 104 82 104 23 0
46 -4 52 -8z"/>
					<path d="M6840 5245 l0 -255 55 0 55 0 0 113 0 112 103 -1 102 -1 3 -112 3
-111 49 0 50 0 0 255 0 255 -50 0 -50 0 0 -95 c0 -52 -3 -95 -7 -95 -5 -1 -52
-2 -106 -3 l-97 -2 0 98 0 97 -55 0 -55 0 0 -255z"/>
					<path d="M7790 5245 l0 -255 45 0 c42 0 45 2 45 26 l0 26 30 -26 c58 -48 141
-43 192 12 35 38 46 61 53 118 10 69 -9 128 -55 178 -36 38 -42 41 -93 41 -42
0 -63 -6 -86 -23 l-31 -23 0 91 0 90 -50 0 -50 0 0 -255z m214 45 c73 -27 75
-207 2 -226 -75 -18 -120 23 -120 110 0 97 47 143 118 116z"/>
					<path d="M6190 5416 c0 -54 0 -55 -32 -58 -29 -3 -33 -6 -33 -33 0 -27 4 -30
33 -33 l32 -3 0 -129 c0 -127 0 -129 26 -149 20 -16 41 -21 85 -21 l59 0 0 39
c0 37 -2 40 -32 43 l-33 3 -3 108 c-3 105 -2 107 20 108 13 1 29 1 36 0 7 -1
12 11 12 34 0 33 -2 35 -35 35 l-35 0 0 55 0 55 -50 0 -50 0 0 -54z"/>
					<path d="M3300 5363 c-8 -3 -27 -17 -42 -31 l-28 -26 0 27 c0 26 -2 27 -50 27
l-50 0 0 -185 0 -185 50 0 50 0 0 101 c0 119 15 162 62 178 17 6 42 11 54 11
22 0 24 4 24 45 0 43 -1 45 -27 44 -16 0 -35 -3 -43 -6z"/>
					<path d="M3482 5349 c-51 -26 -78 -58 -93 -113 -39 -143 59 -267 199 -253 31
3 67 14 81 25 32 24 73 81 65 93 -10 17 -69 6 -97 -18 -37 -30 -99 -31 -127
-3 -11 11 -20 24 -20 29 0 5 -3 16 -6 25 -5 14 10 16 125 16 150 0 153 1 132
79 -33 122 -147 175 -259 120z m141 -76 c8 -10 17 -27 19 -38 3 -19 -3 -20
-77 -20 -74 0 -80 1 -77 20 7 36 33 55 77 55 27 0 47 -6 58 -17z"/>
					<path d="M3970 5363 c-8 -3 -25 -15 -38 -26 l-23 -21 -6 22 c-4 18 -12 22 -49
22 l-44 0 0 -185 0 -185 49 0 49 0 4 136 c3 121 5 137 22 150 26 19 71 18 90
-2 13 -12 16 -41 16 -150 l0 -134 50 0 50 0 0 121 c0 147 12 179 70 179 51 0
55 -9 60 -157 l5 -138 48 -3 47 -3 0 148 c0 130 -2 152 -20 181 -36 58 -120
70 -185 26 l-38 -25 -23 22 c-24 22 -102 35 -134 22z"/>
					<path d="M4725 5354 c-47 -26 -66 -46 -86 -89 -34 -74 -16 -190 37 -237 57
-50 145 -61 214 -25 44 22 95 81 84 98 -11 18 -66 9 -96 -16 -40 -34 -100 -34
-133 0 -14 13 -25 33 -25 44 0 19 7 21 133 23 l132 3 -3 45 c-8 104 -66 162
-166 167 -42 2 -70 -2 -91 -13z m137 -81 c10 -9 18 -27 18 -40 0 -22 -3 -23
-74 -23 -41 0 -78 4 -81 10 -7 11 12 51 29 62 20 14 89 8 108 -9z"/>
					<path d="M5193 5351 c-17 -11 -36 -28 -42 -38 -9 -15 -10 -13 -11 15 0 32 0
32 -50 32 l-50 0 0 -185 0 -185 50 0 50 0 0 83 c1 150 24 198 99 200 l41 2 0
48 c0 45 -1 47 -27 47 -16 0 -42 -9 -60 -19z"/>
					<path d="M5833 5357 c-41 -15 -70 -44 -78 -79 -7 -28 -7 -28 44 -28 38 0 51 4
51 15 0 31 92 49 118 23 32 -32 -3 -78 -58 -78 -46 0 -121 -23 -144 -45 -34
-32 -37 -109 -5 -144 42 -45 119 -54 184 -21 47 24 45 24 45 5 0 -11 12 -15
48 -15 l47 0 -3 153 c-2 83 -8 162 -14 174 -23 46 -156 69 -235 40z m147 -227
c0 -53 -27 -80 -78 -80 -45 0 -62 14 -62 50 0 30 21 44 90 58 25 6 46 10 48
11 1 1 2 -17 2 -39z"/>
					<path d="M6483 5351 c-38 -18 -73 -61 -73 -91 0 -5 21 -10 46 -10 37 0 50 5
66 25 14 18 29 25 53 25 51 0 65 -9 65 -41 0 -35 -16 -43 -105 -53 -94 -12
-134 -45 -135 -111 0 -97 101 -143 208 -95 34 16 42 16 42 5 0 -11 13 -15 48
-15 l48 0 -4 158 c-2 86 -8 164 -13 173 -25 49 -167 65 -246 30z m157 -221 c0
-53 -27 -80 -78 -80 -43 0 -62 14 -62 46 0 29 21 45 83 60 29 7 53 13 55 13 1
1 2 -17 2 -39z"/>
					<path d="M4452 5178 l3 -183 50 -3 50 -3 -3 186 -3 185 -50 0 -49 0 2 -182z" />
					<path d="M7362 5206 c3 -148 4 -156 27 -180 29 -31 89 -51 123 -43 14 4 41 18
61 31 l37 25 0 -24 c0 -23 4 -25 45 -25 l45 0 0 185 0 185 -49 0 -49 0 -4
-136 c-3 -151 -9 -164 -73 -164 -57 0 -65 20 -65 168 l0 132 -51 0 -51 0 4
-154z"/>
					<path d="M7116 4690 c-119 -36 -133 -231 -19 -285 78 -38 179 2 198 78 7 25 5
27 -27 27 -26 0 -39 -7 -56 -30 -44 -59 -112 -24 -112 56 0 56 17 93 47 100
24 6 73 -18 73 -36 0 -6 18 -10 40 -10 22 0 40 4 40 10 0 27 -28 62 -65 80
-44 21 -72 24 -119 10z"/>
					<path d="M7445 4689 c-68 -21 -95 -64 -95 -147 0 -59 2 -65 38 -104 33 -35 46
-41 88 -45 39 -4 60 0 92 16 52 27 72 64 72 137 0 64 -26 114 -68 133 -35 16
-94 21 -127 10z m95 -69 c21 -21 24 -39 21 -100 -4 -63 -69 -92 -111 -50 -23
23 -28 116 -7 142 25 32 69 36 97 8z"/>
					<path d="M7830 4689 c-13 -6 -28 -15 -32 -22 -5 -9 -11 -7 -21 6 -9 13 -23 18
-42 15 l-30 -3 -3 -142 -3 -143 41 0 40 0 0 93 c0 107 13 137 60 137 41 0 50
-23 50 -134 l0 -96 40 0 40 0 0 103 c0 111 6 127 50 127 44 0 50 -16 50 -127
l0 -103 40 0 40 0 0 119 c0 111 -1 120 -24 144 -36 38 -85 44 -136 16 -29 -17
-43 -20 -48 -12 -8 13 -56 33 -75 32 -7 0 -23 -5 -37 -10z"/>
					<path d="M6877 4483 c-4 -3 -7 -24 -7 -45 l0 -38 45 0 45 0 0 45 0 45 -38 0
c-21 0 -42 -3 -45 -7z"/>
				</g>
			</svg>
		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
